.hide-icon {
    .mx-icon-calendar {
        display: none;
    }
    .mx-input {
        padding: 0.375rem 0.75rem;
    }
}
.input-group-sm > .mx-datepicker {
    padding: 0;
    .mx-input {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        border-radius: 0.25rem;
        border: none;
    }
}
