.toolbar-static-zone {
    height: 50px;
}
.mobile-toolbar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: #002a44;
    box-shadow: 0 0 2px rgba(255, 255, 255, .5);
    border-bottom: 1px rgba(255, 255, 255, .7) solid;
    color: #fff;
    z-index: 1800;
    .toolbar-item {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 50px;
        padding-top: 5px;
        line-height: 1.3;
        text-align: center;
        color: #fff;
        text-decoration: none;
        .bi {
            font-size: 18px;
        }
        .title {
            display: block;
            font-size: 12px;
            font-weight: 300;
        }
        .toolbar-badge {
            position: absolute;
            padding-left: 14px;
            font-size: 103%;
            z-index: 1015;
            top: 14px;
            left: 50%;
        }
    }
}
.toolbar-window {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0,36,58);
    background: linear-gradient(356deg, rgba(0,36,58,1) 0%, rgb(1, 58, 93) 100%);
    overflow: hidden;
    z-index: 1005;
    .toolbar-window-content {
        height: calc(100% - 56px);
        width: 100%;
        background: rgba(255, 255, 255, 0.2);
        overflow-y: auto;
    }
    .toolbar-window-menu-item {
        color: #fff;
        text-decoration: none;
    }
}
