.header {
    position: relative;
    background: rgb(0,36,58);
    background: linear-gradient(356deg, rgba(0,36,58,1) 0%, rgb(1, 58, 93) 100%);
    .logo {
        display: inline-block;
        color: rgba(255, 255, 255, 0);
        background: url("/images/logo/png600_dark.png");
        background-size: contain;
        width: 100px;
        height: 37px;
        line-height: 42px;
        margin: 3px 5px 5px 15px;
    }
    .logo-sub {
        margin-left: 10px;
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 22px;
        line-height: 42px;
        font-weight: 200;
        white-space: nowrap;
    }
    .menu {
        margin: 0;
        text-align: right;
        li {
            display: inline-block;
            vertical-align: middle;
            text-decoration: none;
            line-height: 45px;
            a {
                margin-left: 15px;
                color: #eee;
                text-decoration: none;
            }
            a:hover {
                color: #fff;
                text-decoration: none;
            }
        }
    }
    .theme-toggle {
        span {
            position: relative;
            display: block;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            overflow: hidden;
            font-size: 0;
            outline: 1px solid #999;
            cursor: pointer;
            box-shadow: 0 0 2px #999;
        }
        span:before {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 9px;
            height: 18px;
            background: #fff;
        }
        span:after {
            content: " ";
            position: absolute;
            top: 0;
            left: 9px;
            display: block;
            width: 9px;
            height: 18px;
            background: #000;
        }
    }
}
