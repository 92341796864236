html, body, #app {
    height: 100%;
}
.kub-usadba-theme {
    //background: #e4e9e0;
    background: #fff;
    height: 100%;
    overflow: auto;
    color: #222;
    min-height: auto;
    .main-height {
        position: relative;
        min-height: 100vh;
    }
    .logo{
        //font-family: "Roboto Condensed", serif;
        display: inline-block;
        font-size: 170%;
        font-weight: 700;
        color: #fff;
        text-decoration: none;
        line-height: 56px;
        .ips-text {
            display: none;
        }
    }
    .logo-incub{
        display: inline-block;
        font-size: 170%;
        font-weight: 700;
        color: #fff;
        text-decoration: none;
        line-height: 33px;
        padding-top: 9px;
        .ips-text {
            display: block;
            font-size: 50%;
            font-weight: 400;
            line-height: 18px;
            margin-bottom: 8px;
        }
    }
    .topmenu-line{
        position: relative;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        background: linear-gradient(150deg, #97c74e 0%, #2baf9c 100%);
        z-index: 2;
    }
    .topmenu {
        .item {
            display: inline-block;
            color: #fff;
            text-decoration: none;
            line-height: 56px;
        }
        a.item:hover, .item.link:hover {
            color: #fff;
            text-decoration: underline;
        }
    }
    .topmenu-incub {
        .item {
            display: inline-block;
            color: #fff;
            text-decoration: none;
            line-height: 70px;
        }
        a.item:hover, .item.link:hover {
            color: #fff;
            text-decoration: underline;
        }
    }
    .list-group-item.active {
        background: #6dc170;
        //background: linear-gradient(150deg, #97c74e 0%, #2baf9c 100%);
        border: #2baf9c;
    }
    .accordion-button {
        background: #f3f3f3;
    }
    .accordion-button:not(.collapsed) {
        background: #1cb79b;
        color: #fff;
    }
    .mobile-toolbar {
        background: #2baf9c;
        background: linear-gradient(150deg, #63bb73 0%, #2baf9c 100%);
    }
    .toolbar-window {
        background: #2baf9c;
        background: linear-gradient(150deg, #97c74e 0%, #2baf9c 100%);
        .menu-item {
            color: #fff;
            text-decoration: none;
        }
    }

    .footer {
        padding: 20px 0;
        background: #333;
        color: #888;
    }
    .footer a {
        color: #aaa;
    }
    .footer a:hover {
        color: #7EC242;
    }
}
