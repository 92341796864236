body {
    background: #eef4fe;
}
.default-theme {
    background: #eef4fe;
    //background: linear-gradient(40deg, #e1e1e1, #f3f3f3 100%);
}
.theme-wrapper {
    height: 100%;
    overflow: auto;
    min-height: 100vh;
}
.theme-wrapper.not-scroll {
    overflow: hidden;
}

.footer {
    padding: 20px 0;
     background: #1c2e3f;
     color: #ccc;
     a {
         color: #fff;
     }
 }

h1, .h1 {
    font-size: 2.2rem;
}
.table {
    background: #fff;
    --bs-table-color: auto;
}
.table > :not(caption) > * > * {
    //color: #111;
    background: #fff;
}
.form-select:focus, .form-control:focus, .btn:focus {
    outline: 0;
    box-shadow: none;
}
.link {
    cursor: pointer;
    transition: .2s;
}
.base-link {
    color: #000;
    text-decoration: none;
}
.base-link:hover {
    text-decoration: underline;
}
@each $name, $color in $theme-colors {
    .hover-#{$name}:hover {
        color: $color;
    }
}
.hover-primary:hover {
    color: $primary;
}
.card-btn {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    cursor: pointer;
    transition: .3s;
}
.card-btn:hover {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
    cursor: pointer;
}
.btn-client-search {
    position: fixed;
    bottom: 5vh;
    left: 3vw;
    z-index: 5;
}
.btn-micro, .btn-group-micro > .btn {
    padding: 0.12rem 0.24rem;
    font-size: 0.6rem;
    border-radius: 0.2rem;
}
.ymap-container {
    height: 100%;
}
.img-box img {
    max-width: 100%;
}

.suggestion {
    position: absolute;
    width: 100%;
    z-index: 5;
}

.payment-btn {
    transition: .3s;
}
.payment-btn:hover {
    background: #e9eef6;
}
.base-colors {
    background-color: #fff;
    color: #222;
}
