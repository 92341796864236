.paper {
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(51, 51, 51, 0.2);
}
.paper-1 {
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(51, 51, 51, 0.2);
}
.paper-2 {
    background: #fff;
    box-shadow: 0px 1px 5px 0px rgba(51, 51, 51, 0.23);
}
.paper-3 {
    background: #fff;
    box-shadow: 0px 1px 10px 0px rgba(51, 51, 51, 0.28);
}
.paper-4 {
    background: #fff;
    box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.3);
}

.paper-success-1 {
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(45, 153, 91, 0.7);
}
.paper-success-2 {
    background: #fff;
    box-shadow: 0px 1px 5px 0px rgba(45, 153, 91, 0.7);
}
.paper-success-3 {
    background: #fff;
    box-shadow: 0px 1px 10px 0px rgba(45, 153, 91, 0.7);
}
.paper-success-4 {
    background: #fff;
    box-shadow: 0px 1px 15px 0px rgba(45, 153, 91, 0.7);
}
