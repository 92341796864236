.live-search-products-results {
    position: relative;
    .live-search-products-results-content {
        position: absolute;
        top: 2px;
        left: 0;
        width: 100%;
        max-height: 60vh;
        overflow-x: auto;
        background: #eef4fe;
        box-shadow: 0 0 3px #5084a2;
        border-radius: var(--bs-card-border-radius);
        cursor: pointer;
        z-index: 5;
        .card-body {
            color: var(--bs-link-color);
        }
        .card {
            transition: .3s;
        }
        .card:hover {
            background-color: #eee;
        }
        .product-info {
            font-size: .8rem;
        }
    }
}
.dark-theme .live-search-products-results-content {
    background: #001928;
    background: linear-gradient(40deg, #001928, #002135 100%);
    box-shadow: 0 0 3px #5084a2;
    .card:hover {
        background-color: #033656;
    }
}
